import React, { useState } from 'react';
import useDesigns from '../hooks/useDesigns';
const App = () => {
  const [certificateName, setCertificateName] = useState('');
  const { create } = useDesigns();

  const handleCreateCertificate = (e) => {
    e.preventDefault();
    if (!certificateName.trim()) {
      alert("Certificate name cannot be empty!");
      return;
    }
    create({ name: certificateName });
    setCertificateName('');

  };

  return (
    <div className="text-white mx-auto px-20">
      <div className="bg-white shadow-md rounded-lg p-6 mb-4 space-y-4">
        {/* Breadcrumb Navigation */}
        <div className="text-gray-500 text-sm flex items-center space-x-2">
          <a href='/'>Designs</a>
          <span className="text-black">/</span>
          <span className="text-black font-semibold">New</span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <form
          onSubmit={handleCreateCertificate}
          className="bg-white p-8 rounded-md shadow-lg w-1/3 text-center text-black"
        >
          <h2 className="text-2xl font-semibold mb-4">Create New Certificate</h2>
          <input
            type="text"
            value={certificateName}
            onChange={(e) => setCertificateName(e.target.value)}
            placeholder="Enter certificate name"
            className="w-full p-3 border border-gray-300 rounded-md mb-4"
          />
          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200 mb-4"
          >
            Create
          </button>
          <a
            className="text-gray-500 hover:text-gray-700 cursor-pointer"
            href='/'
          >
            Cancel
          </a>
        </form>
      </div>
    </div>
  );
};

export default App;
