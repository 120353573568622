import React, { useState } from 'react';
import useLogin from './hooks/useLogin';
const App = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useLogin();


  const handleLogin = (e) => {
    e.preventDefault();
    login({ email, password });
  };


  return (
    <div className="text-gray-800 mx-auto px-4 sm:px-6 lg:px-8 h-screen flex items-center justify-center">
      <div className="w-full max-w-md text-center">
        <div className="bg-white p-8 rounded-md shadow-lg text-black">
          <h2 className="text-3xl font-semibold mb-6 text-blue-600">Author Login</h2>
          <form onSubmit={handleLogin} className="space-y-4">
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"

            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default App;
