// AdminDashboard.js
import React from 'react';
import Layout from './Layout';
import { Router } from 'react-router-dom';
const AdminDashboard = () => (
  <Layout>

    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Overview</h2>
      <div className="bg-white p-4 rounded shadow">
        <p>This is where the main content of the dashboard will go.</p>
        <p>Use this space for analytics, user management, and settings.</p>
      </div>
    </div>
  </Layout>
);

export default AdminDashboard;
