import React, { useState } from 'react';
import useTemplates from '../hooks/useTemplates';

const App = () => {
  const { loading, create } = useTemplates()
  const [certificateName, setCertificateName] = useState('');
  const [paperSize, setPaperSize] = useState('A4');
  const [orientation, setOrientation] = useState('Portrait');
  const handleCreateCertificate = (e) => {

    create({ name: certificateName })
  };

  if (loading) {
    return <h1>Loading</h1>
  }

  return (
    <div className="text-white mx-auto px-20">
      <div className="bg-white shadow-md rounded-lg p-6 mb-4 space-y-4">
        {/* Breadcrumb Navigation */}
        <div className="text-gray-500 text-sm flex items-center space-x-2">
          <a href='/authors'>Templates</a>
          <span className="text-black">/</span>
          <span className="text-black font-semibold">New</span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <form
          onSubmit={handleCreateCertificate}
          className="bg-white p-8 rounded-md shadow-lg w-1/3 text-center text-black"
        >
          <h2 className="text-2xl font-semibold mb-4">Create New Template</h2>
          <input
            type="text"
            value={certificateName}
            onChange={(e) => setCertificateName(e.target.value)}
            placeholder="Enter certificate name"
            className="w-full p-3 border border-gray-300 rounded-md mb-4"
          />


          <div className="mt-2 p-4 border border-gray-300 rounded-md bg-white">
            <h3 className="text-lg font-medium text-gray-700 mb-4">Format</h3>
            <div className="flex flex-col gap-4">
              <div>
                <label className="text-sm text-gray-800 font-medium">Paper Size:</label>
                <select className="w-full p-2 mt-1 border rounded-md" value={paperSize} onChange={(e) => setPaperSize(e.target.value)}>
                  <option value="A4">A4</option>
                  <option value="US Letter">US Letter</option>
                </select>
              </div>
              <div>
                <label className="text-sm text-gray-800 font-medium">Orientation:</label>
                <select className="w-full p-2 mt-1 border rounded-md" value={orientation} onChange={(e) => setOrientation(e.target.value)}>
                  <option value="Portrait">Portrait</option>
                  <option value="Landscape">Landscape</option>
                </select>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200 mb-4"
          >
            Create
          </button>
          <a
            className="text-gray-500 hover:text-gray-700 cursor-pointer"
            href='/'
          >
            Cancel
          </a>
        </form>
      </div>
    </div>
  );
};

export default App;
