import { useState } from "react";
import axios from "axios";

const useTemplates = () => {

  const [loading, setLoading] = useState(false);
  const [bgImage, setBgImage] = useState(null);
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');


  //Fetches the data from the server
  const fetch = () => {
    setLoading(true);
    axios.get("/authors/templates",
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          window.location.href = "/authors";
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Fetch a single template 
  const fetchTemplate = ({ id }) => {
    setLoading(true);
    axios.get(`/authors/templates/${id}`,
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          window.location.href = "/authors";
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  const create = ({ name }) => {
    setLoading(true);
    axios.post("/authors/templates/new", { name },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          window.location.href = "/authors";
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }


  //update the template 
  const update = ({ id = null, data }) => {

    if (!id) {
      return;
    }
    setLoading(true);
    console.log("DATA", data)
    axios.patch(`/authors/templates/${id}`,
      { data },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Upload template background image

  const uploadBg = (formData) => {
    setLoading(true);
    axios.post(`/authors/templates/upload_bg`,
      formData,
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          setBgImage(response.data.url);
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }





  return {
    loading,
    create,
    uploadBg,
    fetch,
    update,
    bgImage
  };
}
export default useTemplates;