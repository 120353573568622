// AdminDashboard.js
import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
const AdminDashboard = (props) => {
  const [users, setUsers] = useState([])
  useEffect(() => {
    if (props && props.users) {
      setUsers(props.users)
    }
  }, [props])
  return (
    <Layout>

      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold mb-4">User List</h2>
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          {users.length > 0 ? (
            users.map((user) => (
              <div
                key={user.id}
                className="flex items-center p-4 border-b last:border-b-0 hover:bg-gray-50"
              >
                {user.avatar ? (
                  <img
                    src={user.avatar}
                    alt={user.name}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                ) : (
                  <div className="w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center mr-4">
                    <span className="text-gray-600">{user.name[0]}</span>
                  </div>
                )}
                <div>
                  <h3 className="text-lg font-medium text-gray-800">{user.name}</h3>
                  <p className="text-gray-500">{user.email}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="p-4 text-center text-gray-500">No users found.</p>
          )}
        </div>
      </div>
    </Layout>
  )
};

export default AdminDashboard;
