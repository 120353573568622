import React, { useState } from "react";
import axios from "axios";

const useLogin = () => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const [loading, setLoading] = useState(false);
  const login = ({ email, password }) => {
    setLoading(true);
    axios.post("/authors/sign_in", { email, password }).
      then((response) => {
        if (response.status === 200) {
          // Redirect to the dashboard
          window.location.href = "/authors";
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Logout 

  const logout = () => {
    setLoading(true)
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    axios.delete("/authors/sign_out",
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        //Redirect 
        // window.location = "/authors"
        if (response.status === 204) {
          window.location = "/authors"
        }
      }).catch((error) => {
        console.log("ERROR", error)
      })
  }

  return { loading, login, logout };
}
export default useLogin;