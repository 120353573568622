import React, { useState } from 'react';
import { FaEdit, FaSave } from 'react-icons/fa';

function TopHeaderComponent() {
  const [designName, setDesignName] = useState('My design #1');
  const [isEditing, setIsEditing] = useState(false);
  const [tempName, setTempName] = useState(designName);

  const handleSave = () => {
    setIsEditing(false);
    setDesignName(tempName);
    // Handle save action here
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && tempName.trim() !== '') {
      handleSave();
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6  mb-4 space-y-4">
      {/* Breadcrumb Navigation */}
      <div className="text-gray-500 text-sm flex items-center space-x-2">
        <a href='/'>Designs</a>
        <span className="text-black">/</span>
        <span className="text-black font-semibold">{designName}</span>
      </div>

      {/* Title and Editable Input aligned to the left */}
      <div className="flex items-center space-x-4">
        {isEditing ? (
          <div className="flex items-center space-x-3">
            <input
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Enter design name"
              className="border border-gray-300 focus:border-blue-500 p-2 rounded-md shadow-sm transition-all focus:outline-none focus:ring-2 focus:ring-blue-200 max-w-xs"
              aria-label="Edit design name"
            />
            <button
              onClick={handleSave}
              disabled={tempName.trim() === designName || tempName.trim() === ''}
              className={`flex items-center px-4 py-2 rounded-md transition-all focus:outline-none focus:ring-2 ${tempName.trim() !== designName && tempName.trim() !== ''
                ? 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-400'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              aria-label="Save design name"
            >
              <FaSave className="mr-2" /> Save
            </button>
          </div>
        ) : (
          <div className="flex items-center space-x-3">
            <h1 className="text-2xl font-bold text-black">{designName}</h1>
            <button
              onClick={() => setIsEditing(true)}
              className="flex items-center text-blue-500 hover:text-blue-600 transition-all focus:outline-none focus:ring-2 focus:ring-blue-400 rounded-lg p-2"
              aria-label="Edit design name"
              title="Edit design name"
            >
              <FaEdit className="mr-1" /> Edit
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default TopHeaderComponent;
