import { useEffect, useState } from "react";
import axios from "axios";

const useDesigns = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null)
  const [pendingUpdate, setPendingUpdate] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [bgImage, setBgImage] = useState(null)

  const getLastPathPart = () => {
    // Remove any query parameters and hash from the pathname
    const cleanPath = window.location.pathname.replace(/[?#].*$/, '');
    console.log("CLEAN PATH", cleanPath)
    // Split the path and filter out any empty parts
    const pathParts = cleanPath.split('/').filter(Boolean);
    // Get the last part or provide a fallback message
    return pathParts.length > 0 ? pathParts[pathParts.length - 1] : 'No path found';
  };


  useEffect(() => {
    if (token && pendingUpdate) {
      triggerUpdate(pendingUpdate);
      setPendingUpdate(null);
    }
  }, [token, pendingUpdate]);

  const update = (updateData) => {
    if (token) {
      triggerUpdate(updateData);
    } else {
      setPendingUpdate(updateData);  // Save the update until token is available
    }
  };


  useEffect(() => {

    // Set the initial last part of the URL
    setToken(getLastPathPart());

    // Update the last part on back/forward navigation
    const handlePopState = () => {
      setToken(getLastPathPart());
    };

    // Listen to the popstate event
    window.addEventListener('popstate', handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    fetchTemplates();
    fetchCertificate();
  }, [token]);

  const fetchCertificate = () => {
    if (!token || token === null) {
      return
    }

    axios.get(`/iframe/certificates/${token}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        setCertificate(response.data);
      });
  }

  const openPdf = () => {
    window.open(`/iframe/certificates/${token}/pdf`, "self");
  }

  const openImage = () => {
    window.open(`/iframe/certificates/${token}/png`, "self");
  }

  //update the template 
  const triggerUpdate = ({ data }) => {

    console.log("TOKEN", token)
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    setLoading(true);
    axios.patch(`/iframe/certificates/${token}`,
      { data },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //fetch the templates
  const fetchTemplates = () => {
    if (!token || token === null) {
      return
    }
    axios.get(`/iframe/templates/${token}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        setTemplates(response.data);

        console.log("RESPONSE", response.data)
      });

  };

  //Updated the background image 
  const uploadBg = (formData) => {
    formData.append("token", token)
    setLoading(true);
    axios.post(`/iframe/certificates/upload_bg`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          setBgImage(response.data.url);
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }



  //Publish the design 

  const publish = ({ id }) => {
    setLoading(true)
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    axios.post(`/iframe/certificates/${token}/publish`, {}, {
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }).
      then((response) => {
        if (response.status === 200) {
          console.log("OKEY")
        }
      }).catch((response) => {
        console.log("ERROR", response)
      }).finally(() => {
        setLoading(false)
      })
  }


  return {
    templates,
    fetchTemplates,
    loading,
    update,
    publish,
    uploadBg,
    openPdf,
    openImage,
    bgImage
  };
}

export default useDesigns;  