import { FaPlus, FaSignOutAlt } from 'react-icons/fa';
import React from 'react';
import useLogin from './users/hooks/useLogin';
const HeaderComponent = () => {
  const { logout } = useLogin()
  return (
    <div className="bg-white shadow-md rounded-lg p-6 flex justify-between items-center mb-10">
      <div>
        <p className="text-gray-500">Designs</p>
        <h1 className="text-2xl font-bold">Designs</h1>
      </div>
      <div className=" flex justify-between items-center mb-10 gap-5">

        <a
          href='/new'
          className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-blue-600 transition-all">
          <FaPlus />
          <span>Create Design</span>
        </a>

        <button
          onClick={() => {
            logout()
          }}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-blue-600 transition-all">
          <FaSignOutAlt />
          <span>Sign Out</span>
        </button>

      </div>
    </div>
  );
};

export default HeaderComponent;
