// import React, { useState, useEffect } from 'react';
// import { FabricJSCanvas, useFabricJSEditor, } from 'fabricjs-react';
// const fabric = require('fabric');

// const App = () => {
//   const { editor, onReady } = useFabricJSEditor({});
//   const [jsonInput, setJsonInput] = useState('');
//   const [fontSize, setFontSize] = useState(24);
//   const [fontFamily, setFontFamily] = useState("Arial");
//   // Set up dimensions similar to A4 landscape
//   const A4_WIDTH = 1120; // Convert mm to px
//   const A4_HEIGHT = 780;


//   console.log("EDITR", editor)


//   const onAddCircle = () => {
//     editor?.addCircle();
//   };

//   const onAddRectangle = () => {
//     editor?.addRectangle();
//   };


//   const exportToJSON = () => {
//     if (editor) {
//       const jsonData = editor.canvas.toJSON();
//       console.log("Exported JSON:", jsonData);
//       setJsonInput(JSON.stringify(jsonData, null, 2));
//       return jsonData;
//     }
//   };

//   const loadFromJSON = () => {
//     if (editor && jsonInput) {
//       try {
//         const jsonData = JSON.parse(jsonInput);

//         editor.canvas.loadFromJSON(jsonData, () => {
//           editor.canvas.renderAll();  // Force render after loading
//           console.log("Canvas successfully loaded from JSON!");
//         });

//         // Additional renderAll call to ensure the canvas updates
//         setTimeout(() => editor.canvas.renderAll(), 50);

//       } catch (error) {
//         console.error("Invalid JSON format. Please check the JSON structure:", error);
//       }
//     } else {
//       console.warn("Editor not initialized or JSON input is empty.");
//     }
//   };

//   // Use a basic example JSON for initial testing
//   useEffect(() => {
//     const exampleJSON = JSON.stringify({
//       version: "4.6.0",
//       objects: [
//         {
//           type: "circle",
//           left: 100,
//           top: 100,
//           radius: 50,
//           fill: "red"
//         },
//         {
//           type: "rect",
//           left: 200,
//           top: 200,
//           width: 100,
//           height: 100,
//           fill: "blue"
//         }
//       ]
//     });
//     setJsonInput(exampleJSON); // Set initial JSON for testing
//   }, []);

//   const handleOnReady = (canvas) => {
//     onReady(canvas);  // Initializes the editor
//     canvas.setWidth(A4_WIDTH);  // Set canvas width
//     canvas.setHeight(A4_HEIGHT);  // Set canvas height



//     canvas.backgroundColor = '#F6F6F4';  // Set canvas background color



//     const svgUrl = 'http://localhost:3000/mycorner.svg'; // Replace with your SVG URL


//     const mysvg = fabric.loadSVGFromURL("https://upload.wikimedia.org/wikipedia/commons/8/84/Example.svg")

//     mysvg.then(function (objects, options) {
//       console.log("LOAA")
//       console.log("OBJECTS", objects)
//     })

//     const mysvgpath = "https://svgshare.com/i/1C24.svg"

//     const bgimaage = fabric.FabricImage.fromURL(mysvgpath)


//     bgimaage.then(function (img) {
//       console.log("LOAA")
//       img.selectable = false
//       canvas.add(img)
//       console.log("CANVSS", canvas)
//     })

//     // Add Title Text
//     const titleText = new fabric.IText('Certificate Title', {
//       left: canvas.getWidth() / 2,
//       top: 200,
//       width: 1000,
//       fontSize: 60,
//       fontFamily: 'Times New Roman',
//       fontWeight: 'bold',
//       textAlign: 'center',
//       originX: 'center',
//       editable: true
//     });
//     titleText.set({
//       fill: '#333', // Text color
//       shadow: new fabric.Shadow({
//         color: 'rgba(0,0,0,0.3)',
//         blur: 3,
//         offsetX: 2,
//         offsetY: 2
//       })
//     });
//     canvas.add(titleText);

//     // Add From Text
//     const fromText = new fabric.Textbox('Presented by XYZ Organization', {
//       left: canvas.getWidth() / 2,
//       top: 300,
//       width: 1000,
//       fontSize: 40,
//       fontFamily: 'Times New Roman',
//       textAlign: 'center',
//       originX: 'center',
//       editable: true
//     });
//     canvas.add(fromText);

//     // Add Recipient Name
//     const recipientText = new fabric.Textbox('Recipient Name', {
//       left: canvas.getWidth() / 2,
//       top: 450,
//       width: 1000,
//       fontSize: 50,
//       fontFamily: 'Times New Roman',
//       fontStyle: 'italic',
//       textAlign: 'center',
//       originX: 'center',
//       editable: true
//     });
//     canvas.add(recipientText);

//     // Add Description Text
//     const descriptionText = new fabric.Textbox('For outstanding performance in...', {
//       left: canvas.getWidth() / 2,
//       top: 550,
//       width: 1200,
//       fontSize: 30,
//       fontFamily: 'Times New Roman',
//       textAlign: 'center',
//       originX: 'center',
//       editable: true
//     });
//     canvas.add(descriptionText);

//     // Add Date
//     const dateText = new fabric.Textbox('Date', {
//       left: canvas.getWidth() / 2 - 200,
//       top: canvas.getHeight() - 130,
//       width: 400,
//       fontSize: 25,
//       fontFamily: 'Times New Roman',
//       textAlign: 'center',
//       originX: 'center',
//       editable: true
//     });
//     canvas.add(dateText);

//     // Add Signature
//     const signatureText = new fabric.Textbox('Signature', {
//       left: canvas.getWidth() / 2 + 200,
//       top: canvas.getHeight() - 130,
//       width: 400,
//       fontSize: 25,
//       fontFamily: 'Times New Roman',
//       textAlign: 'center',
//       originX: 'center',
//       editable: true
//     });
//     canvas.add(signatureText);

//     canvas.on('mouse:down', (event) => {
//       const clickedItem = event.target;
//       if (clickedItem) {
//         console.log('Clicked Item:', clickedItem);
//         // You can access properties like clickedItem.type, clickedItem.left, clickedItem.top, etc.
//       } else {
//         console.log('Canvas clicked, not an item');
//       }
//     });


//     canvas.on('mouse:dblclick', (event) => {
//       const clickedItem = event.target;
//       if (clickedItem && clickedItem instanceof fabric.IText) {
//         clickedItem.enterEditing(); // Enter editing mode
//         canvas.renderAll();
//       }
//     });


//   };
//   // Function to add editable text to the canvas
//   const addEditableText = () => {
//     if (editor && editor.canvas) {  // Ensure editor and canvas are available
//       const canvas = editor.canvas;

//       const editableText = new fabric.IText("Double-click to edit", {
//         left: 100,
//         top: 100,
//         fontSize: 24,
//         fill: 'black',
//         editable: true,
//         id: `text_${new Date().getTime()}`,  // Assign a unique ID
//       });

//       canvas.add(editableText);
//       canvas.setActiveObject(editableText); // Set as active for editing
//       editableText.enterEditing();          // Enable text editing immediately
//       canvas.renderAll();                   // Render to apply changes
//     } else {
//       console.warn("Editor or canvas not initialized yet.");
//     }
//   };

//   // Function to update font size
//   const updateFontSize = (size) => {
//     const activeObject = editor.canvas.getActiveObject();
//     if (activeObject && activeObject instanceof fabric.IText) {
//       activeObject.set("fontSize", size);
//       editor.canvas.renderAll();
//     }
//     setFontSize(size);
//   };

//   // Function to update font family
//   const updateFontFamily = (family) => {
//     const activeObject = editor.canvas.getActiveObject();
//     if (activeObject && activeObject instanceof fabric.IText) {
//       activeObject.set("fontFamily", family);
//       editor.canvas.renderAll();
//     }
//     setFontFamily(family);
//   };
//   return (

//     <div>
//       <div class="flex items-center space-x-4 p-4 border-b border-gray-300 bg-white">
//         <div class="w-10 h-10 border rounded-md bg-teal-500"></div>

//         <div class="flex items-center space-x-2">
//           <span class="text-sm text-gray-500">Size</span>
//           <input
//             value={fontSize}
//             type="number"
//             class="w-16 p-1 border rounded-md text-center"
//             onChange={(e) => updateFontSize(parseInt(e.target.value, 10))} />
//         </div>

//         <div class="flex items-center space-x-2">
//           <span class="text-sm text-gray-500">Font</span>
//           <select class="p-1 border rounded-md" onChange={(e) => updateFontFamily(e.target.value)}>
//             <option>Marcellus</option>
//             <option>Roboto</option>
//             <option>Open Sans</option>
//           </select>
//         </div>

//         <button class="px-3 py-1 border rounded-md text-gray-700">Regular</button>




//       </div>




//       <FabricJSCanvas className="sample-canvas" onReady={handleOnReady} style={{ width: '1080px', height: '1080px' }}
//       />
//     </div>
//   );
// };

// export default App;
import React, { useState, useEffect } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import TopHeaderComponent from './TopHeaderComponent';
const fabric = require('fabric');

const App = () => {
  const { editor, onReady } = useFabricJSEditor({});
  const [jsonInput, setJsonInput] = useState('');
  const [fontSize, setFontSize] = useState(24);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [activeMenu, setActiveMenu] = useState('Templates');
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // Set up dimensions similar to A4 landscape
  const A4_WIDTH = 1120; // Convert mm to px
  const A4_HEIGHT = 780;


  const onAddCircle = () => {
    editor?.addCircle();
  };

  const onAddRectangle = () => {
    editor?.addRectangle();
  };

  const exportToJSON = () => {
    if (editor) {
      const jsonData = editor.canvas.toJSON();
      console.log("Exported JSON:", jsonData);
      setJsonInput(JSON.stringify(jsonData, null, 2));
      return jsonData;
    }
  };

  const loadFromJSON = () => {
    if (editor && jsonInput) {
      try {
        const jsonData = JSON.parse(jsonInput);

        editor.canvas.loadFromJSON(jsonData, () => {
          editor.canvas.renderAll(); // Force render after loading
          console.log("Canvas successfully loaded from JSON!");
        });

        // Additional renderAll call to ensure the canvas updates
        setTimeout(() => editor.canvas.renderAll(), 50);
      } catch (error) {
        console.error("Invalid JSON format. Please check the JSON structure:", error);
      }
    } else {
      console.warn("Editor not initialized or JSON input is empty.");
    }
  };

  // Use a basic example JSON for initial testing
  useEffect(() => {
    const exampleJSON = JSON.stringify({
      version: "4.6.0",
      objects: [
        {
          type: "circle",
          left: 100,
          top: 100,
          radius: 50,
          fill: "red"
        },
        {
          type: "rect",
          left: 200,
          top: 200,
          width: 100,
          height: 100,
          fill: "blue"
        }
      ]
    });
    setJsonInput(exampleJSON); // Set initial JSON for testing
  }, []);

  const handleOnReady = (canvas) => {
    onReady(canvas); // Initializes the editor
    canvas.setWidth(A4_WIDTH); // Set canvas width
    canvas.setHeight(A4_HEIGHT); // Set canvas height

    canvas.backgroundColor = '#F6F6F4'; // Set canvas background color

    if (selectedTemplate) {
      // Load the selected template onto the canvas
      loadFromJSON();
    }
  };

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template);
    setJsonInput(template.json); // Set JSON input for the selected template
  };

  const templates = [
    { id: 1, name: 'Template 1', image: '/template1.png', json: '{"version":"4.6.0","objects":[{"type":"circle","left":100,"top":100,"radius":50,"fill":"red"}]}' },
    { id: 2, name: 'Template 2', image: '/template2.png', json: '{"version":"4.6.0","objects":[{"type":"rect","left":200,"top":200,"width":100,"height":100,"fill":"blue"}]}' },
    { id: 3, name: 'Template 3', image: '/template3.png', json: '{"version":"4.6.0","objects":[{"type":"text","left":150,"top":150,"text":"Sample Text","fontSize":20}]}' },
  ];

  return (
    <div className='text-white mx-auto px-20'>
      <TopHeaderComponent />
      <div className="flex w-full">
        {/* Sidebar for Tools */}
        <div className="w-64 p-4 border-r border-gray-300 bg-gray-100">
          <ul className="space-y-4">
            {[
              { name: 'Templates', icon: '📄' },
              { name: 'Background', icon: '🖌️' },
              { name: 'Elements', icon: '✨' },
              { name: 'Texts', icon: '✍️' },
              { name: 'Attributes', icon: '🔧' },
              { name: 'QRCodes', icon: '🔳' },
            ].map((menuItem) => (
              <li key={menuItem.name}>
                <button
                  className={`block w-full px-3 py-2 text-left flex items-center gap-2 rounded-md transition-all ${activeMenu === menuItem.name ? 'bg-blue-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-200'
                    }`}
                  onClick={() => setActiveMenu(menuItem.name)}
                >
                  <span>{menuItem.icon}</span> {menuItem.name}
                </button>
                {activeMenu === menuItem.name && (
                  <div className="mt-2 grid grid-cols-2 gap-2 overflow-y-auto h-64 w-full">
                    {menuItem.name === 'Templates' && templates.map((template) => (
                      <div
                        key={template.id}
                        className="border rounded-md cursor-pointer w-full shadow-sm hover:shadow-md transition-shadow"
                        onClick={() => handleTemplateClick(template)}
                      >
                        <img src={template.image} alt={template.name} className="w-full h-32 object-cover rounded-t-md" />
                        <p className="text-center py-1 text-sm">{template.name}</p>
                      </div>
                    ))}
                    {menuItem.name === 'Background' && (
                      <div className="border rounded-md cursor-pointer w-full shadow-sm hover:shadow-md transition-shadow" />
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>


        {/* Main Editor Canvas */}
        <div className="flex-1">
          <div className="flex items-center space-x-4 p-4 border-b border-gray-300 bg-white">
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500">Font Size</span>
              <input
                value={fontSize}
                type="number"
                className="w-16 p-1 border rounded-md text-center"
                onChange={(e) => setFontSize(parseInt(e.target.value, 10))}
              />
            </div>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500">Font Family</span>
              <select className="p-1 border rounded-md" onChange={(e) => setFontFamily(e.target.value)}>
                <option>Arial</option>
                <option>Times New Roman</option>
                <option>Roboto</option>
                <option>Open Sans</option>
              </select>
            </div>
          </div>
          <FabricJSCanvas
            className="sample-canvas"
            onReady={handleOnReady}
            style={{ width: '100%', height: '780px' }}
          />
        </div>
      </div>
    </div>


  );
};

export default App;

