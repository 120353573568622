import { useState } from "react";
import axios from "axios";

const useDesigns = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bgImage, setBgImage] = useState(null);

  //update the template 
  const update = ({ id = null, data }) => {

    if (!id) {
      return;
    }
    setLoading(true);
    axios.patch(`/users/certificates/${id}`,
      { data },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //fetch the templates
  const fetchTemplates = () => {
    axios.get("/users/templates", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        setTemplates(response.data.templates);
      }
      );

  };

  //Create a new certificate
  const create = (name) => {
    setLoading(true);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    axios.post("/users/certificates",
      { name },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          window.location.href = "/";
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Publish the design 

  const publish = ({ id }) => {
    setLoading(true)
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    axios.post(`/designs/${id}/publish`, {}, {
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }).
      then((response) => {
        if (response.status === 200) {
          console.log("OKEY")
        }
      }).catch((response) => {
        console.log("ERROR", response)
      }).finally(() => {
        setLoading(false)
      })
  }

  const uploadBg = (formData) => {
    setLoading(true);
    axios.post(`/designs/upload_bg`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          setBgImage(response.data.url);
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  return { templates, fetchTemplates, create, loading, update, publish, uploadBg, bgImage };
}

export default useDesigns;  