

import React, { useState, useEffect } from 'react';
import { useFabricJSEditor } from 'fabricjs-react';
import { FaTrash, FaCopy } from 'react-icons/fa';
import HeaderComponent from '../Header';


const App = ({ templates }) => {
  const [jsonInput, setJsonInput] = useState('');

  console.log("PROPS ARE", templates)

  // Use a basic example JSON for initial testing
  useEffect(() => {
    const exampleJSON = JSON.stringify({
      version: "4.6.0",
      objects: [
        {
          type: "circle",
          left: 100,
          top: 100,
          radius: 50,
          fill: "red"
        },
        {
          type: "rect",
          left: 200,
          top: 200,
          width: 100,
          height: 100,
          fill: "blue"
        }
      ]
    });
    setJsonInput(exampleJSON); // Set initial JSON for testing
  }, []);



  return (
    <div >
      <HeaderComponent />
      <div className="flex w-full">
        {/* Design Cards Section */}
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-6">
          {templates.map((design) => (
            <a
              key={design.id}
              href={`/authors/templates/${design.id}`} // Rails route
              className="bg-white p-4 shadow-md rounded-md hover:shadow-lg transition-all flex flex-col"
              style={{ width: '100%', textDecoration: 'none' }}
            >
              {/* Image Placeholder */}
              <img
                src={design.cover_image || `https://via.placeholder.com/400x300`}
                alt={design.name}
                className="w-full h-48 object-cover rounded-t-md"
              />
              {/* Card Content */}
              <div className="mt-4">
                <h2 className="text-lg font-semibold">{design.name}</h2>
                <div className="flex items-center space-x-4 mt-4">
                  <span className="flex items-center text-gray-500 transition-all">
                    <FaCopy className="mr-1" /> Copy
                  </span>
                  <span className="flex items-center text-red-500 transition-all">
                    <FaTrash className="mr-1" /> Delete
                  </span>
                </div>
              </div>
            </a>
          ))}
        </div>


      </div>
    </div>


  );
};

export default App;

