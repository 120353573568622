import axios from "axios";
//Upload image to s3 bucket
const uploadToS3Bucket = ({ file, signed_url }) => {

  fetch(signed_url,
    {
      method: 'PUT',
      body: file,
    }).then((response) => {
      if (response.status === 200) {
        //Process uploaded image This stores it to cloudflare 
        console.log('Image uploaded successfully');
      }
    });
};

export default uploadToS3Bucket;