// AdminDashboard.js
import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
const AdminDashboard = (props) => {
  const [authToken, setAuthToken] = useState(null)
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(authToken).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
    });
  };

  useEffect(() => {
    if (props && props.token) {
      setAuthToken(props.token)
    }
  }, [props])
  return (
    <Layout>
      <button
        onClick={() => {
          console.log("HELLO WORLD")
        }}
        className="text-white bg-green-500 hover:bg-green-600 px-3 py-1 rounded-md focus:outline-none mb-4"
      >
        Generate Token
      </button>

      <div>
        <h2 className="text-xl font-semibold mb-4">Authentication Token</h2>
        <div className="bg-white shadow-md rounded-lg p-4 flex items-center justify-between">
          <p className="text-gray-800 mr-4">
            <strong>Access Token:</strong> {authToken}
          </p>
          <button
            onClick={copyToClipboard}
            className="text-white bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded-md focus:outline-none"
          >
            Copy
          </button>
        </div>
        {copySuccess && (
          <p className="mt-2 text-green-500 text-sm">{copySuccess}</p>
        )}
      </div>
    </Layout>
  )
};

export default AdminDashboard;
