// Layout.js
import React from 'react';

const Sidebar = () => (
  <div className="w-64 bg-gray-800 text-white h-screen p-4">
    <h2 className="text-lg font-bold mb-4">Admin Dashboard</h2>
    <ul>
      <a href="/admins" className="text-white">
        <li className="mb-2 p-2 hover:bg-gray-700 rounded">
          Users
        </li>
      </a>


      <a href="/admins/plans" className="text-white">
        <li className="mb-2 p-2 hover:bg-gray-700 rounded">
          Plans
        </li>
      </a>
    </ul>
  </div>
);

const Header = () => (
  <div className="bg-white border-b border-gray-200 p-4 flex justify-between items-center">
    <h1 className="text-xl font-semibold">Admin Panel</h1>
    <button className="bg-blue-500 text-white px-4 py-2 rounded">Log Out</button>
  </div>
);

const Layout = ({ children }) => (
  <div className="flex">
    <Sidebar />
    <div className="flex-1 flex flex-col">
      <Header />
      <main className="flex-1 bg-gray-100 p-4">
        {children}
      </main>
    </div>
  </div>
);

export default Layout;
