import React, { useState, useEffect } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import TopHeaderComponent from '../TopHeaderComponent';
import { MdBrush, MdUpload } from 'react-icons/md';
import { AiOutlineStar } from 'react-icons/ai';
import { FiEdit, FiPlus, FiSettings } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';
import { GoTools } from 'react-icons/go';
import { MdPlusOne } from "react-icons/md";
import { FaCopy, FaFilePdf, FaTrash } from 'react-icons/fa';
import useTemplate from '../hooks/useTemplate';
import FontSelect from '../../utils/FontSelect';
import { useDebouncedCallback } from 'use-debounce';
import ToolbarColorPicker from '../../utils/ToolbarColorPicker';
const fabric = require('fabric');


const App = (props) => {
  const { editor, onReady } = useFabricJSEditor({});
  const [fontSize, setFontSize] = useState(24);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [fontColor, setFontColor] = useState("#000000");
  const [activeMenu, setActiveMenu] = useState('Background');
  const [paperSize, setPaperSize] = useState('A4');
  const [orientation, setOrientation] = useState('Portrait');
  const [currentObject, setCurrentObject] = useState(null);
  const { uploadBg, bgImage = null, update, fetch, publish, loading } = useTemplate();
  const [textObjects, setTextObjects] = useState([]);
  const [dynamicTextObjects, setDynamicTextObjects] = useState([]);
  const [dynamicText, setDynamicText] = useState('');


  const A4_WIDTH = 1122; // Convert mm to px
  const A4_HEIGHT = 794;


  const debounced = useDebouncedCallback(
    // function
    (data) => {
      update({
        id: props.id,
        data
      });
    },
    // delay in ms
    1000
  );




  //Delete selected object
  const handleDelete = (index) => {
    //Check the current object and update the font size and family
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      editor.canvas.remove(activeObject);
      editor.canvas.renderAll();
    }

  };

  const handleCopy = async () => {
    //Check the current object and update the font size and family
    const activeObject = editor.canvas.getActiveObject()
    const cloneObject = await activeObject.clone()
    cloneObject.set({ left: activeObject.left + 10, top: activeObject.top + 10 })
    editor.canvas.add(cloneObject)
    editor.canvas.setActiveObject(cloneObject)
  }



  //add svg image 
  const addSvgImage = async (svg) => {
    console.log("SVG", svg)
    const { objects, options } = await fabric.loadSVGFromURL(svg.image)
    const helloworld = fabric.util.groupSVGElements(objects, options);
    helloworld.set('fill', '#000000'); // Hex code
    editor.canvas.add(helloworld);
    editor.canvas.centerObject(helloworld);

  }


  useEffect(() => {
    if (bgImage) {
      const bgimaage = fabric.FabricImage.fromURL(bgImage)
      //Go through all the objects and find the image object with id backgroundImage 
      //and remove it from the canvas

      const objects = editor.canvas.getObjects();

      objects.forEach(function (object) {
        if (object.id === "myTestImage") {
          editor.canvas.remove(object);
        }
      });

      bgimaage.then(function (img) {
        img.id = "myTestImage"
        img.selectable = false
        img.evented = false
        img.width = editor.canvas.width
        img.height = editor.canvas.height
        editor.canvas.add(img)
        editor.canvas.sendObjectToBack(img)

        //Editor.canvas.renderAll();
        editor.canvas.toObject(
          ['id',
            'selectable',
            'evented',
            'dynamic',
            'editable'
          ]);
      })
      editor.canvas.renderAll();
    }
  }, [bgImage]);


  useEffect(() => {
    if (editor) {
      //Check the current object and update the font size and family
      //Go through the objects and find the text object with id textObject
      //and update the font size and family
      const objects = editor.canvas.getObjects().filter(obj => {
        return obj.type === 'i-text' && obj.dynamic != true;
      });
      const dynamicTexts = editor.canvas.getObjects().filter(obj => {
        return obj.type === 'i-text' && obj.dynamic === true;
      });
      setTextObjects([...objects]);
      setDynamicTextObjects([...dynamicTexts]);
    }
  }, [editor && editor.canvas]);


  useEffect(() => {
    if (editor) {
      //Check the current object and update the font size and family
      //Go through the objects and find the text object with id textObject
      //and update the font size and family
      const object = editor.canvas.getActiveObject()

      console.log("OBJECT", object)
      //Check if the object is a text object
      object.set({ fontFamily: fontFamily.label });
      editor.canvas.renderAll();
    }
  }, [fontFamily]);


  useEffect(() => {
    if (editor) {
      //Check the current object and update the font size and family
      //Go through the objects and find the text object with id textObject
      //and update the font size and family
      const object = editor.canvas.getActiveObject()
      //Check if the object is a text object\

      object.set({ fill: fontColor });
      editor.canvas.renderAll();
      editor.canvas.fire('object:modified', { target: object });
    }
  }, [fontColor]);

  useEffect(() => {
    if (editor) {
      //Check the current object and update the font size and family
      //Go through the objects and find the text object with id textObject
      //and update the font size and family
      const object = editor.canvas.getActiveObject()

      console.log("OBJECT", object)

      if (object && object.type === 'i-text') {
        object.set({ fontSize: fontSize });

        //Check if the object is a text object
        editor.canvas.renderAll();
      }
    }
  }, [fontSize]);




  const handleBackgroundImageUpload = ({ target: { files } }) => {
    const file = files[0];
    if (!file) return; // Early return if no file

    if (!file.type.startsWith("image/")) {
      console.error("Please upload a valid image file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", props.id);
      uploadBg(formData);
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    reader.readAsDataURL(file);
  };

  //Add text to the canvas
  const addText = (text) => {
    //Unselect all objects
    editor.canvas.discardActiveObject();

    const textObject = new fabric.IText(text, {
      fontFamily: "Arial",
      fontSize: 30,
      fill: "#000000",
    });

    textObject.setControlsVisibility({
      mt: false, // middle top
      mb: false, // middle bottom
      ml: false, // middle left
      mr: false, // middle right
      bl: true,  // bottom left
      br: true,  // bottom right
      tl: true,  // top left
      tr: true   // top right
    });
    editor.canvas.calcOffset();
    editor.canvas.centerObject(textObject);
    editor.canvas.add(textObject);
    editor.canvas.renderAll();
  }

  //Add text to the canvas
  const addDynamicText = (name) => {
    //Name must be a valid variable name
    if (name === "") {
      alert("Please enter a valid variable name")
      return;
    }
    //Unselect all objects
    editor.canvas.discardActiveObject();

    const textObject = new fabric.IText(`[${name}]`, {
      fontFamily: "Arial",
      fontSize: 30,
      fill: "#000000",
      dynamic: true,
      id: name,
      editable: false

    });

    textObject.setControlsVisibility({
      mt: false, // middle top
      mb: false, // middle bottom
      ml: false, // middle left
      mr: false, // middle right
      bl: true,  // bottom left
      br: true,  // bottom right
      tl: true,  // top left
      tr: true   // top right
    });
    if (editor) {
      editor.canvas.calcOffset();
      editor.canvas.centerObject(textObject);
      editor.canvas.add(textObject);
    }
  }
  const graphics = [
    { id: 1, name: 'Template 1', image: '/graphics/olive-wreath-icon.svg' },
    { id: 2, name: 'Template 2', image: '/graphics/ribbon.svg' },
    { id: 3, name: 'Template 3', image: '/graphics/graduation-cap.svg' },
    { id: 4, name: 'Template 3', image: '/graphics/circle.svg' },
    { id: 5, name: 'Template 3', image: '/graphics/square.svg' },
    { id: 6, name: 'Template 3', image: '/graphics/line.svg' },
    { id: 7, name: 'Template 3', image: '/graphics/horizontal-line.svg' }
  ];

  const texts = [
    "[recipient_name]",
    "[certificate_uuid]",
    "[certificate_issued_on]"
  ];

  const handleOnReady = (canvas) => {
    onReady(canvas);
    canvas.enableRetinaScaling = false
    canvas.setWidth(A4_WIDTH);
    canvas.setHeight(A4_HEIGHT);
    canvas.backgroundColor = '#F6F6F4';
    console.log("This is the ready canvas", canvas)

    canvas.loadFromJSON(props.data, () => {
      setTimeout(() => canvas.renderAll(), 50);
    });


    fabric.devicePixelRatio = 1;
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);





    canvas.on('mouse:click', (event) => {
      const clickedItem = event.target;
      if (clickedItem && clickedItem instanceof fabric.IText) {
        clickedItem.enterEditing(); // Enter editing mode
        canvas.renderAll();
      }
      // const line = new fabric.Line([50, 100, 250, 100], {
      //   stroke: 'black',      // Line color
      //   strokeWidth: 2,       // Line width
      // })
      // canvas.add(line);
      // canvas.renderAll();

    });

    canvas.on('mouse:down', function (event) {
      const clickedObject = canvas.findTarget(event.e);
      if (clickedObject) {
        setCurrentObject(clickedObject);
      } else {
        setCurrentObject(null)
      }
    });

    // addControlWithIcon(signatureText, FaCopy, (eventData, transform) => {
    //   const target = transform.target;
    //   const clone = fabric.util.object.clone(target);
    //   clone.set({ left: target.left + 10, top: target.top + 10 });
    //   canvas.add(clone);
    //   canvas.requestRenderAll();
    // });

    // Example with coordinates within a typical canvas
    ///Take note of when the object is modified
    canvas.on('object:modified', function (event) {
      // Get the modified object
      canvas.calcOffset();
      const data = canvas.toObject(['id', 'selectable', 'evented', 'dynamic', 'editable']);
      update({
        id: props.id,
        data
      });
    });

    canvas.on('object:scaling', function (e) {
      const obj = e.target;
      if (obj) {
        debounced(obj);
        // Perform actions based on the resizing, such as adjusting font size instead of scaling
      }
    });
    // Listen for when a new selection is created
    canvas.on('selection:created', (e) => {
      const selectedObjects = e.selected;
      if (selectedObjects && selectedObjects.length === 1) {
        setFontFamily({
          label: selectedObjects[0].fontFamily,
          value: selectedObjects[0].fontFamily
        });
        setFontColor(selectedObjects[0].fill);
        setFontSize(selectedObjects[0].fontSize);

      }
      // Add any additional logic you want to perform when a new object is selected
      const data = canvas.toObject(['id', 'selectable', 'evented', 'dynamic', 'editable']);
      update({
        id: props.id,
        data
      });
    });

    // Listen for when the selection is updated
    canvas.on('selection:updated', (e) => {
      const selectedObjects = e.selected;


      if (selectedObjects && selectedObjects.length === 1) {
        setFontFamily({
          label: selectedObjects[0].fontFamily,
          value: selectedObjects[0].fontFamily
        });
        setFontSize(selectedObjects[0].fontSize);
        setFontColor(selectedObjects[0].fill);

        const data = canvas.toObject(['id', 'selectable', 'evented', 'dynamic', 'editable']);
        update({
          id: props.id,
          data
        });
      }      // This can be used to detect when additional objects are added to the selection
    });

    //Added a new object to the canvas

    canvas.on('object:added', function (e) {
      const obj = e.target;
      obj.lockUniScaling = true;


      if (obj.type === 'i-text' && obj.dynamic != true) {
        obj.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: true,
          br: true,
          tl: true,
          tr: true
        })
        setTextObjects((prev) => [...prev, obj]);
      } else if (obj.type === 'i-text' && obj.dynamic === true) {
        obj.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: true,
          br: true,
          tl: true,
          tr: true
        })
        setDynamicTextObjects((prev) => [...prev, obj]);
      }
      const data = canvas.toObject(['id', 'selectable', 'evented', 'dynamic', 'editable']);
      update({
        id: props.id,
        data
      });
    });


  };


  console.log("PROPS", props)

  return (
    <div className='text-white mx-auto px-10 py-5'>
      <TopHeaderComponent name={props?.name} />
      <div className="flex w-full">
        {/* Sidebar for Tools */}
        <div className="w-64 p-4 border-r border-gray-300 bg-gray-100">
          <h2 className="text-lg font-semibold mb-4 text-gray-700">Tools</h2>
          <ul className="space-y-4">
            {[
              { name: 'Background', icon: <MdBrush /> },
              { name: 'Graphics', icon: <AiOutlineStar /> },
              { name: 'Text', icon: <FiEdit /> },
              { name: 'Smart Text', icon: <GoTools /> },
              { name: 'Settings', icon: <FiSettings /> },
            ].map((menuItem) => (
              <li key={menuItem.name}>
                <button
                  className={`block
                     w-full 
                     px-3 
                     py-2 
                     text-left 
                     flex 
                     items-center
                     gap-2 
                     rounded-md 
                     transition-all ${activeMenu === menuItem.name ? 'bg-blue-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-200'
                    }`}
                  onClick={() => setActiveMenu(menuItem.name)}
                >
                  <span>{menuItem.icon}</span> {menuItem.name}
                </button>
                {activeMenu === menuItem.name && menuItem.name === 'Background' && (
                  <div className="mt-2 p-4 border border-gray-300 rounded-md bg-white">
                    <input
                      type="file"
                      accept="image/*,image/svg+xml"
                      onChange={(e) => handleBackgroundImageUpload(e)}
                      className="block
                            w-full
                            text-sm 
                            text-gray-500 
                            file:mr-4 
                            file:py-2 
                            file:px-4 
                            file:rounded-md
                            file:border-0 
                            file:text-sm 
                            file:font-semibold 
                            file:bg-blue-50 
                            file:text-blue-700
                            hover:file:bg-blue-100"
                    />
                    <p className="text-xs text-gray-500 mt-2">
                      We accept JPG and PNG files. The maximum image size should be 2MB.</p>
                  </div>
                )}
                {activeMenu === menuItem.name && menuItem.name === 'Graphics' && (
                  <div className="mt-2 p-4 border border-gray-300 rounded-lg bg-white shadow-sm">
                    <div className="mt-6 w-full p-4 overflow-y-auto" style={{ maxHeight: '40vh' }}>
                      <div className="grid grid-cols-2 gap-6"> {/* Increased gap from 4 to 6 */}
                        {graphics.map((graphic) => (
                          <div
                            key={graphic.id}
                            className="cursor-pointer 
                                  w-full 
                                  h-full 
                                  overflow-hidden
                                  rounded-lg 
                                  shadow-md 
                                  hover:shadow-xl 
                                  hover:scale-105 t
                                  ransition-transform 
                                  duration-200 
                                  bg-gray-50
                                  flex 
                                  flex-col 
                                  items-center 
                                  justify-center 
                                  p-4" // Added `justify-center` to center the image          
                            onClick={() => addSvgImage(graphic)}
                          >
                            <img
                              src={graphic.image}
                              alt={graphic.name}

                              loading="lazy"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mt-4 flex justify-center">
                      <button
                        onClick={addSvgImage}
                        aria-label="Upload SVG Image"
                        className="w-full
                                max-w-xs 
                                flex 
                                items-center 
                                justify-center 
                                gap-2
                                px-4 
                                py-2 
                                bg-green-500
                                text-white
                                font-semibold
                                rounded-lg 
                                hover:bg-green-600
                                transition
                                ease-in-out
                                duration-150 
                                shadow-lg
                                hover:shadow-xl
                                focus:outline-none
                                focus:ring-2 
                                focus:ring-green-400">
                        <MdUpload className="text-xl" />
                        Upload
                      </button>
                    </div>
                  </div>
                )}
                {activeMenu === menuItem.name && menuItem.name === 'Text' && (
                  <div className="mt-2
                          p-2 
                          border
                          border-gray-300
                          bg-white 
                          w-full
                          shadow-md 
                          rounded-lg">
                    <div className="space-y-4 overflow-y-auto" style={{ maxHeight: "40vh" }}>
                      {textObjects.map((object, index) => {
                        return (
                          <div
                            key={index}
                            className="cursor-pointer
                                    flex 
                                    flex-col
                                    bg-white 
                                    hover:bg-yellow-100
                                    rounded-sm"
                            onClick={() => {
                              editor.canvas.setActiveObject(object);
                            }}
                          >
                            <p className="text-base 
                            p-2
                            font-medium 
                            text-gray-800 
                            text-color-yellow">{object.text}
                            </p>

                            {index !== texts.length - 1 && <hr className="mt-4 border-t border-gray-300" />}
                          </div>
                        )
                      })}
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        onClick={() => {
                          addText("Sample Text")
                        }}
                        className="w-full 
                        flex
                        items-center
                        justify-center
                        gap-2
                        px-4 
                        py-2
                        bg-green-500
                        text-white 
                        font-medium 
                        rounded-lg
                        hover:bg-green-600
                        transition 
                        duration-150
                        shadow-md 
                        hover:shadow-lg
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-green-400"
                      >
                        <MdPlusOne className="text-lg" />
                        Add text
                      </button>
                    </div>
                  </div>




                )}
                {activeMenu === menuItem.name && menuItem.name === 'Smart Text' && (
                  <div className="mt-2 p-4 border border-gray-300 bg-white w-full max-w-lg mx-auto shadow-md rounded-lg">
                    <div className="space-y-0 overflow-y-auto " style={{ maxHeight: '40vh' }}>
                      {dynamicTextObjects.map((textObject, index) => (
                        <div key={index}
                          className="cursor-pointer flex flex-col mt-2"
                          onClick={() => { editor.canvas.setActiveObject(textObject); }}>

                          <p className="text-base font-medium text-gray-800">{textObject.text}</p>

                          {index !== texts.length - 1 &&
                            <hr className="mt-4 border-t border-gray-300" />}
                        </div>
                      ))}
                    </div>

                    <form className="flex bg-blue-200 w-30 mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        //Add the dynamic text to the canvas
                        addDynamicText(dynamicText)
                      }}>

                      <input
                        value={dynamicText}
                        type="text"
                        placeholder="Enter text"
                        className="p-2 
                        w-9/12
                          bg-red
                          text-black
                          border 
                          border-gray-300 
                          rounded-l-md 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-blue-500"
                        onChange={(e) => {
                          //The dynamic text should only allow alphabets and underscore
                          const regex = /^[a-zA-Z_]*$/;
                          if (regex.test(e.target.value)) {

                            setDynamicText(e.target.value)
                          } else {
                            alert("Only alphabets and underscore are allowed")
                          }
                        }
                        }
                      />
                      <button
                        className="inline-flex 
                        w-3/12
                        items-center 
                        justify-center 
                        px-3 
                        bg-blue-600 
                        text-white 
                        rounded-r-md 
                        hover:bg-blue-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-blue-500"

                      >
                        <FiPlus className="text-white" />
                      </button>
                    </form>

                  </div>


                )}
                {activeMenu === menuItem.name && menuItem.name === 'Settings' && (
                  <div className="mt-2 
                  p-4 border
                   border-gray-300 
                   rounded-md 
                   bg-white">
                    <h3 className="text-lg font-medium text-gray-700 mb-4">Format</h3>
                    <div className="flex flex-col gap-4">
                      <div>
                        <label className="text-sm text-gray-800 font-medium">Paper Size:</label>
                        <select
                          className="w-full p-2 mt-1 border rounded-md"
                          value={paperSize}
                          onChange={(e) => setPaperSize(e.target.value)}>
                          <option value="A4">A4</option>
                          <option value="US Letter">US Letter</option>
                        </select>
                      </div>
                      <div>
                        <label className="text-sm text-gray-800 font-medium">Orientation:</label>
                        <select
                          className="w-full p-2 mt-1 border rounded-md"
                          value={orientation}
                          onChange={(e) => setOrientation(e.target.value)}>
                          <option value="Portrait">Portrait</option>
                          <option value="Landscape">Landscape</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Editor Canvas */}
        <div className="flex-1">
          <div style={{ display: 'grid', placeItems: 'center' }}>

            <div className="flex 
              items-center 
              space-x-4 
              p-4 
              border-b 
              border-gray-300 
              bg-white w-full">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-black">Font Size</span>
                <input
                  value={fontSize}
                  type="number"
                  className="w-16 p-1 border rounded-md text-center text-black"
                  onChange={(e) => setFontSize(parseInt(e.target.value, 10))}
                />
              </div>
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500"
                  style={{ fontFamily: "Comic Sans MS" }}>
                  Font Family
                </span>
                <FontSelect value={fontFamily}
                  onChange={(font) => {
                    setFontFamily(font)
                  }} />

              </div>
              <div className="flex items-center space-x-2 mt-33">

                <ToolbarColorPicker
                  value={fontColor}
                  onChange={(color) => {

                    setFontColor(color);

                  }}

                />
              </div>
              <button
                className="p-2
                 bg-red-500 
                 text-white 
                 rounded-md 
                 hover:bg-blue-600 
                 transition 
                 duration-150"
                onClick={handleDelete}>
                <FaTrash />
              </button>

              <button
                className="p-2 
                bg-red-500 
                text-white 
                rounded-md 
                hover:bg-blue-600 
                transition 
                duration-150"
                onClick={handleCopy}
              >
                <FaCopy />
              </button>
              <a
                className="flex 
                items-center 
                gap-2 p-2 bg-blue-700 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-red-300 transition-colors duration-150 cursor-pointer"
                href={`/authors/templates/${props.id}/pdf`}
                target='_blank'

              >
                <FaFilePdf /> PDF Preview
              </a>

              <button
                onClick={() => {
                  publish({ id: props.id })
                }}

                className={`flex items-center px-4 py-2 rounded-md transition-all focus:outline-none focus:ring-2 bg-green-500 text-white hover:bg-blue-600 focus:ring-blue-400`}
                aria-label="Save design name"
              >
                <FaCheck className="mr-2" /> Publish
              </button>

            </div>

          </div>
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <FabricJSCanvas
              className="sample-canvas"
              onReady={handleOnReady}
              style={{
                width: '100%',
                height: '780px',
                backgroundColor: ""
              }}
            />
          </div>

        </div>
      </div>


    </div>
  );
};

export default App;
