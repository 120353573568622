import React, { useState, useEffect } from 'react';
import { FaTrash, FaCopy } from 'react-icons/fa';
import HeaderComponent from '../../Header';

const App = (props) => {
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    // Fetch certificates from the API
    setCertificates(props.certificates);
  }, [props]);

  return (
    <div className="bg-white min-h-screen">
      <HeaderComponent />
      <div className="container mx-auto py-8 px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Design Cards Section */}
          {certificates.map((design) => (
            <a
              key={design.id}
              href={`/designs/${design.id}`} // Rails route
              className="bg-white shadow-lg rounded-lg hover:shadow-xl transition-transform transform hover:scale-105"
              style={{ textDecoration: 'none' }}
            >
              {/* Image Placeholder */}
              <div className="aspect-w-16 aspect-h-9 rounded-t-lg overflow-hidden">
                <img
                  src={design.cover_image || "https://via.placeholder.com/400x250?text=Design+Image"}
                  alt={design.name}
                  className="w-full h-full object-cover"
                />
              </div>
              {/* Card Content */}
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">{design.name}</h2>
                <div className="flex items-center justify-between mt-4">
                  <button className="flex items-center text-gray-600 hover:text-gray-800 transition-colors">
                    <FaCopy className="mr-2" /> Copy
                  </button>
                  <button className="flex items-center text-red-500 hover:text-red-700 transition-colors">
                    <FaTrash className="mr-2" /> Delete
                  </button>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
