// AdminDashboard.js
import React from 'react';
import Layout from './Layout';

const Users = () => (
  <Layout>
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Users</h2>
      <div className="bg-white p-4 rounded shadow">
        <p>Users page</p>
      </div>
    </div>
  </Layout>
);

export default Users;
